import { useMediaQuery } from 'react-responsive'
import React, { useState, useEffect } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import Row from 'react-bootstrap/Row';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Card, CardBody} from "react-bootstrap";

import CategoryDD from './components/categoryDD'
import Footer from "./components/footer"
import OnSelectEvent from "./components/calendar"
import RegionDD from './components/regionDD'
import Slider from './components/slider';
import FeaturedEvents from './components/featuredEvents';
import SponsorshipCard from './components/sponsorshipCard';

function App() {
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1223px)' })

    const [ user, setUser ] = useState();
    const [ profile, setProfile ] = useState();

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(
        () => {
            if (user) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        setProfile(res.data);
                    })
                    .catch((err) => console.log(err));
            }
        },
        [ user ]
    );

    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        googleLogout();
        setProfile(null);
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="app">
            {profile ? (
                <div>
                    {
                        isDesktopOrLaptop && 
                        <div className={`form-block-wrapper form-block-wrapper--is-home`} >
                            <Navbar className = "header" bg="black" data-bs-theme="dark">
                                <Navbar.Brand href="home" style={{marginLeft:"25px"}}>
                                    <h1>MindVyne</h1>
                                </Navbar.Brand>
                                <Navbar.Collapse className="justify-content-end">
                                    <Button variant="outline-light" onClick={handleShow} style={{marginRight:"25px"}}>Menu</Button>
                                </Navbar.Collapse>
                            </Navbar>
                            <Offcanvas show={show} onHide={handleClose}>
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title>Menu</Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    Stay tuned for exciting new features coming soon—check back regularly for updates!
                                    <hr/>
                                    <Row className = "whole-col">
                                        <p>{profile.email}</p>
                                        <button onClick={logOut}>Log out</button>
                                    </Row>
                                </Offcanvas.Body>
                            </Offcanvas>
                            <Slider />
                            <div className = "whole-col">
                                <div className = "one-half-col">
                                    <div className = "one-half-col">
                                        <RegionDD/>
                                    </div>
                                    <div className = "one-half-col">
                                        <CategoryDD/>
                                    </div>
                                    <div className = "whole-col">
                                        <FeaturedEvents/>
                                    </div>
                                </div>
                                <div className = "one-half-col">
                                    <div className = "whole-col">
                                        <SponsorshipCard/>
                                    </div>
                                </div>
                            </div>
                            <div className = "whole-col">
                                <div className = "whole-col">
                                    <b>Want to add your event to the calendar?</b>
                                </div>
                                <div className = "whole-col">
                                    <a href = "https://forms.gle/f9AxBkc6hsfmHUEx9" target="_blank" rel="noreferrer">
                                        <button style = {{borderColor:"#e68574", backgroundColor:"#e68574", color:"white"}}>Click Here!</button>
                                    </a>
                                </div>
                                <OnSelectEvent/>
                            </div>
                            <Footer/>
                        </div>
                    }
                    {
                        isTabletOrMobile &&
                        <div className={`form-block-wrapper form-block-wrapper--is-home`} >
                            <Navbar className = "header" bg="black" data-bs-theme="dark">
                                <Navbar.Brand href="home" style={{marginLeft:"25px"}}>
                                    <h1>MindVyne</h1>
                                </Navbar.Brand>
                                <Navbar.Collapse className="justify-content-end">
                                    <Button variant="outline-light" onClick={handleShow} style={{marginRight:"25px"}}>Menu</Button>
                                </Navbar.Collapse>
                            </Navbar>
                            <Offcanvas show={show} onHide={handleClose}>
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title>Menu</Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    Stay tuned for exciting new features coming soon—check back regularly for updates!
                                    <hr/>
                                    <Row className = "whole-col">
                                        <p>{profile.email}</p>
                                        <button onClick={logOut}>Log out</button>
                                    </Row>
                                </Offcanvas.Body>
                            </Offcanvas>
                            <Slider />
                            <div className = "whole-col">
                                <div className = "whole-col">
                                    <RegionDD/>
                                </div>
                                <div className = "whole-col">
                                    <CategoryDD/>
                                </div>
                                <div className = "whole-col">
                                    <FeaturedEvents/>
                                </div>
                                <div className = "whole-col">
                                    <SponsorshipCard/>
                                </div>
                            </div>
                            <div className = "whole-col">
                                <div className = "whole-col">
                                    <b>Want to add your event to the calendar?</b>
                                </div>
                                <div className = "whole-col">
                                    <a href = "https://forms.gle/f9AxBkc6hsfmHUEx9" target="_blank" rel="noreferrer">
                                        <button style = {{borderColor:"#e68574", backgroundColor:"#e68574", color:"white"}}>Click Here!</button>
                                    </a>
                                </div>
                                <OnSelectEvent/>
                            </div>
                            <Footer/>
                        </div>
                    }
                </div> 
            ) : (
                <div>
                    {
                        isDesktopOrLaptop && 
                        <div className={`form-block-wrapper form-block-wrapper--is-about`} >
                            <Navbar className = "header" bg="black" data-bs-theme="dark">
                                <Navbar.Brand href="home" style={{marginLeft:"25px"}}>
                                    <h1>MindVyne</h1>
                                </Navbar.Brand>
                            </Navbar>
                            <br/>
                            <Row className = "whole-col">
                                <div className="container">
                                    <div className="form-container sign-in-container">
                                        <div style = {
                                            {
                                                "display": "flex", "flex-direction": "column", "align-items": "center", 
                                                "justify-content": "center", "text-align": "center", "padding": "0 50px",
                                                "height": "100%"
                                            }
                                    }>
                                        <h1>Welcome!</h1>
                                        <div className="social-container">
                                                <button onClick={() => login()}>Sign in with Google </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="overlay-container">
                                        <div className="overlay">
                                            <div className="overlay-panel overlay-left">
                                                <h1>Welcome Back!</h1>
                                                <p>Stay connected by signing in!</p>
                                                <button className="ghost" id="signIn">
                                                    Sign In
                                                </button>
                                            </div>
                                            <div className="overlay-panel overlay-right">
                                                <h1>Hello, Friend!</h1>
                                                <p>
                                                    Subscribers gain exclusive access to a vibrant community where ideas germinate, thoughts 
                                                    converge, and professional connections flourish. Subscribe to unlock the fullpotential of 
                                                    MindVyne, delving into a treasure trove of biotech communities and events.
                                                </p>
                                                <a href = "https://buy.stripe.com/test_dR6aIH5OlgNB0lWaEE" target="_blank" rel="noreferrer"><button className="ghost " disabled>Subscribe</button></a>
                                                <i>* Sign in for free with Google login — subscriptions coming soon!</i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                            <br/>
                            <Footer/>
                        </div>
                    }
                    {
                        isTabletOrMobile && 
                        <div>
                            <div className={`form-block-wrapper form-block-wrapper--is-about`} >
                                <Navbar className = "header" bg="black" data-bs-theme="dark">
                                    <Navbar.Brand href="home" style={{marginLeft:"25px"}}>
                                        <h1>MindVyne</h1>
                                    </Navbar.Brand>
                                </Navbar>
                                <br/>
                                <div className = "whole-col">
                                    <div className = "whole-col">
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <div>
                                                        <br/>
                                                        <h1>Welcome!</h1>
                                                        <div className="social-container">
                                                            <button onClick={() => login()}>Sign in with Google </button>
                                                        </div>
                                                    </div>
                                                </Row>
                                                <Row>
                                                    <br/>
                                                </Row>
                                                <Row>
                                                    <hr/>
                                                </Row>
                                                <Row>
                                                    <br/>
                                                </Row>
                                                <Row>
                                                    <p style = {{color:"#ed6a5e"}}>Don't have an account?</p>
                                                </Row>
                                                <Row>
                                                    <p>
                                                        Subscribers gain exclusive access to a vibrant community where ideas germinate, thoughts 
                                                        converge, and professional connections flourish. Subscribe to unlock the fullpotential of 
                                                        MindVyne, delving into a treasure trove of biotech communities and events.
                                                    </p>
                                                </Row>
                                                <Row>
                                                    <a href = "https://buy.stripe.com/test_dR6aIH5OlgNB0lWaEE" target="_blank" rel="noreferrer"><button disabled>Subscribe</button></a>
                                                    <i>* Sign in for free with Google login — subscriptions coming soon!</i>
                                                </Row>
                                                <br/>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                                <br/>
                                <Footer/>
                            </div>
                        </div>
                    }
                </div>

            )}
        </div>
    );
}
export default App;