import {React, useMemo, useState, useCallback} from 'react';
import Modal from 'react-bootstrap/Modal';

import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";

import events from "../resources/events"
import "../styles.css";

const localizer = momentLocalizer(moment);


export default function OnSelectEvent() {
  const defaultDate = useMemo(() => new Date(2024, 8, 1), [])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [title, setTitle] = useState(false);
  const [location, setLocation] = useState(false);
  const [datetime, setDateTime] = useState(false);
  const [description, setDescription] = useState(false);
  const [url, setUrl] = useState(false);
  const eventModal = useCallback((calEvent) => {
    setShow(true)
    setTitle(calEvent.title)
    setLocation(calEvent.location)
    setDateTime(calEvent.datetime)
    setDescription(calEvent.description)
    setUrl(calEvent.url)
  }, [])

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title>Event Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>{title}</h5>
          <i>{location}&nbsp;-&nbsp;{datetime}</i>
          <p>{description}</p>
        </Modal.Body>
        <Modal.Footer>
          <a href = {url} target="_blank" rel="noreferrer">
            <button className="posting-button">
              Go To Event Posting
            </button>
          </a>
        </Modal.Footer>
      </Modal>
      <Calendar
        defaultDate={defaultDate}
        events={events}
        defaultView="month"
        onSelectEvent={eventModal}
        localizer={localizer}
        style={{ height: "100vh", color: "#e68574", }}
        eventPropGetter={(myEventsList) => {
          const backgroundColor = myEventsList.colorEvento ? myEventsList.colorEvento : '#043740';
          const color = myEventsList.color ? myEventsList.color : 'white';
          return { style: { backgroundColor ,color} }
        }}
      />
    </div>
  )
}