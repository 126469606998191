const events = [
  /* 
  {
    id: ,
    title: "",
    start: new Date(),
    end: new Date(),
    datetime: "",
    location: "",
    description: "",
    url: ""
  },
  */
  {
    id: 1,
    title: 'Digital Health, Life Sciences, and Biotech, Networking Mixer',
    start: new Date(2024, 5, 26, 16),
    end: new Date(2024, 5, 26, 18),
    datetime: "June 26th, 2024 (4-6 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://www.eventbrite.com/e/digital-health-life-sciences-and-biotech-networking-mixer-tickets-920963746357?aff=ebdssbdestsearch"
  },

  {
    id: 2,
    title: 'Unlocking Biological Pathways with Data Science',
    start: new Date(2024, 5, 27, 18),
    end: new Date(2024, 5, 27, 19),
    datetime: "June 27th, 2024 (6-7 PM)",
    location: "Cambridge, MA",
    description: "",
    url: "https://lu.ma/pwzdgzkp?tk=LA2RdJ"
  },

  {
    id: 3,
    title: 'Summer Biotech Community Celebration at Tech Square',
    start: new Date(2024, 6, 10, 16),
    end: new Date(2024, 6, 10, 18, 30),
    datetime: "July 10th, 2024 (4-6:30 PM)",
    location: "Cambridge, MA",
    description: "",
    url: "https://www.eventbrite.com/e/bvs-summer-biotech-community-celebration-at-tech-square-cambridge-tickets-920289289037?aff=ebdssbdestsearch"
  },

  {
    id: 4,
    title: 'HEALTHCARE AI AND TECHNOLOGY INVESTOR SUMMIT',
    start: new Date(2024, 6, 17, 18),
    end: new Date(2024, 6, 17, 21),
    datetime: "July 17th, 2024 (6-9 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://lu.ma/f1mt0ziq?tk=B8mBvS"
  },
  {
    id: 5,
    title: 'Tumor Models Boston Summit',
    start: new Date(2024, 6, 23),
    end: new Date(2024, 6, 25),
    datetime: "July 23rd - 24th, 2024",
    location: "Boston, MA",
    description: "",
    url: "https://tumor-models.com/"
  },
  {
    id: 6,
    title: 'Women of Science: Leadership Lounge',
    start: new Date(2024, 6, 31, 18),
    end: new Date(2024, 6, 31, 21),
    datetime: "July 31st, 2024 (6-9 PM)",
    location: "Cambridge, MA",
    description: "",
    url: "https://lu.ma/uqdqqu2u"
  },
  {
    id: 7,
    title: "MIT Founders + Coffee & Bagels",
    start: new Date(2024,7,7, 8, 45),
    end: new Date(2024,7,7, 10),
    datetime: "August 7th, 2024 (8:45-10 AM)",
    location: "Boston, MA",
    description: "",
    url: "https://lu.ma/nii2uxgs"
  },
  {
    id: 8,
    title: "Summer Biotech Night - Nucleate Dojo",
    start: new Date(2024,7,8, 18),
    end: new Date(2024,7,8, 21),
    datetime: "August 8th, 2024 (6-9 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://lu.ma/xqqn0kw3?tk=TLiVhT"
  },
  {
    id: 9,
    title: "Future of Life & Work in the age of AI",
    start: new Date(2024,7,8, 18, 30),
    end: new Date(2024,7,8, 21),
    datetime: "August 8th, 2024 (6:30-9 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://lu.ma/Boston-8-8"
  },
  {
    id: 10,
    title: "Databricks GenAI Boston Meetup",
    start: new Date(2024, 7, 8, 17),
    end: new Date(2024, 7, 8, 19),
    datetime: "August 8th, 2024 (5-7 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://www.meetup.com/databricks-bug/events/302457927/"
  },
  {
    id: 11,
    title: "South Shore Mixer: A Pharma & Biotech Social!",
    start: new Date(2024, 7, 9, 17),
    end: new Date(2024, 7, 9, 21),
    datetime: "August 9th, 2024 (5-9 PM)",
    location: "boston, MA",
    description: "",
    url: "https://www.eventbrite.com/e/south-shore-mixer-a-pharma-biotech-social-tickets-956379084587?aff=ebdssbdestsearch"
  },
  {
    id: 12,
    title: "Boston Code and Coffee @ CIC",
    start: new Date(2024,7,10, 12),
    end: new Date(2024,7,10, 15),
    datetime: "August 10th, 2024 (12-3 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://www.meetup.com/boston-code-and-coffee/events/302215725/"
  },
  {
    id: 13,
    title: "Leading the Way with J&J: Spotlight on Oncology",
    start: new Date(2024,7,13, 14,30),
    end: new Date(2024,7,13, 18),
    datetime: "August 13th, 2024 (2:30-6 pm)",
    location: "Boston, MA",
    description: "",
    url: "https://www.labcentral.org/news-events/events/leading-the-way-with-j-j-spotlight-on-oncology"
  },
  {
    id: 14,
    title: "Digital Product case study and networking",
    start: new Date(2024,7,14, 18, 30),
    end: new Date(2024,7,14, 20, 45),
    datetime: "August 14th, 2024 (6:30-8:45 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://www.meetup.com/boston-digital-product-leaders/events/302379804/"
  },
  {
    id: 15,
    title: "Cell therapy discussion w/ age1",
    start: new Date(2024,7,15, 18),
    end: new Date(2024,7,15, 21),
    datetime: "August 15th, 2024 (6-9 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://lu.ma/uagungsd?tk=E0iFac"
  },
  {
    id: 16,
    title: "AI Drug Discovery - Ripe or Hype & What's Next?",
    start: new Date(2024,7,15, 17),
    end: new Date(2024,7,15, 19),
    datetime: "August 15th, 2024 (5-7 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://lu.ma/6ddz256g"
  },
  {
    id: 17,
    title: "FoodHack Boston: From Lab to Table - Exploring the Promise of Cellular Agriculture",
    start: new Date(2024, 7, 15, 17, 30),
    end: new Date(2024, 7, 15, 19, 30),
    datetime: "August 15th, 2024 (5:30-7:30 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://lu.ma/hwehc4lx"
  },
  {
    id: 18,
    title: "EnergyBar: Summer Rooftop Party",
    start: new Date(2024,7,15, 17, 30),
    end: new Date(2024,7,15, 19, 30),
    datetime: "August 15th, 2024 (5:30-7:30 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://lu.ma/gbou4bfb"
  },
  {
    id: 19,
    title: "Biotech Meetup- lamplighter Cambridge",
    start: new Date(2024, 7, 15, 18),
    end: new Date(2024, 7, 15,21,30),
    datetime: "August 15th, 2024 (6-9:30 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://www.meetup.com/cambridge-biotech-meetup/events/302672990/"
  },
  {
    id: 20,
    title: "Early-Stage B2B SaaS VC/Founder Boston Coffee Meetup",
    start: new Date(2024,7,19,9),
    end: new Date(2024,7,19,11),
    datetime: "August 19th, 2024 (9-11 AM)",
    location: "Boston, MA",
    description: "",
    url: "https://lu.ma/urw9e3r9"
  },
  {
    id: 21,
    title: "Y Combinator Applicants Power Walk",
    start: new Date(2024, 7, 21, 18),
    end: new Date(2024, 7, 21, 19),
    datetime: "August 21st, 2024 (6-7 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://lu.ma/fmwkw1d3"
  },
  {
    id: 22,
    title: "Boston's Biggest Business, Tech & Entrepreneur Networking Soiree",
    start: new Date(2024, 7, 27, 17,30),
    end: new Date(2024, 7, 27, 20),
    datetime: "August 27th, 2024 (5:30-8 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://www.eventbrite.com/e/bostons-biggest-business-tech-entrepreneur-networking-soiree-tickets-884920600337?aff=oddtdtcreator"
  },
  {
    id: 23,
    title: "Health2Tech & DHAI",
    start: new Date(2024, 8, 4, 18),
    end: new Date(2024, 8, 4, 21),
    datetime: "September 4th, 2024 (6-9 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://lu.ma/acjvn63c"
  },
  {
    id: 24,
    title: "Gallery 1832: Harmony & Mayhem - LabCentral / Bayer Co.Lab",
    start: new Date(2024, 8, 10, 16, 30),
    end: new Date(2024, 8, 10, 18, 30),
    datetime: "September 10th, 2024 (4:30-6:30 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://www.labcentral.org/news-events/events/gallery-1832-presents-harmony-and-mayhem"
  },
  {
    id: 25,
    title: "FAIR in CMC: Addressing Data Silos in Chemistry, Manufacturing, and Control",
    start: new Date(2024,8,11, 17, 45),
    end: new Date(2024,8,11, 19, 45),
    datetime: "September 11th, 2024 (5:45-7:45 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://www.meetup.com/boston-area-group-for-informatics-and-modeling/events/302434536/"
  },
  {
    id: 26,
    title: "Cultured Meat Symposium 2024 (CMS24)",
    start: new Date(2024,8,12, 12),
    end: new Date(2024,8,12, 20),
    datetime: "September 12th, 2024 (12-8 PM)",
    location: "Virtual",
    description: "",
    url: "https://www.meetup.com/boston-life-science-professionals/events/301134266/"
  },
  {
    id: 27,
    title: "Chemical Space Exploration Workshop",
    start: new Date(2024, 8, 25, 14, 30),
    end: new Date(2024, 8, 25, 17),
    datetime: "September 25th, 2024 (2:30-5 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://www.eventbrite.com/e/chemical-space-exploration-workshop-tickets-945172033997?aff=ebdssbdestsearch"
  },
  {
    id: 28,
    title: "2024 Cleantech Open Northeast Regional Finals Awards & Showcase",
    start: new Date(2024,9,1, 16),
    end: new Date(2024,9,1, 20),
    datetime: "October 1st, 2024 (4-8 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://www.eventbrite.com/e/2024-cleantech-open-northeast-regional-finals-awards-showcase-tickets-945185594557?aff=ebdssbdestsearch"
  },
  {
    id: 29,
    title: "BAGIM Sponsored Poster Session - Computational Medicinal Chemistry School",
    start: new Date(2024,9,29, 16),
    end: new Date(2024,9,29, 18),
    datetime: "October 29th, 2024 (4 - 6 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://www.meetup.com/boston-area-group-for-informatics-and-modeling/events/301523437/"
  },
  {
    id: 30,
    title: "Broad Institute Machine Learning in Drug Discovery Symposium 2024",
    start: new Date(2024,10,1, 8, 50),
    end: new Date(2024,10,1, 17, 50),
    datetime: "November 1st, 2024 (8:50 AM - 5:50 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://www.eventbrite.com/e/broad-institute-machine-learning-in-drug-discovery-symposium-2024-tickets-863080175037?aff=ebdssbdestsearch"
  },
  {
    id: 31,
    title: "2nd International Conference on Innovations and Advances in Cancer Research",
    start: new Date(2024,10,6, 8),
    end: new Date(2024,10,6, 18),
    datetime: "November 6th, 2024 (8 AM - 6 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://www.eventbrite.com/e/2nd-international-conference-on-innovations-and-advances-in-cancer-research-tickets-790912148527?aff=ebdssbdestsearch&keep_tld=1"
  },
  {
    id: 32,
    title: "Digital Health & AI Innovation Summit",
    start: new Date(2024,9,7,7),
    end: new Date(2024,9,10,19),
    datetime: "October 7th - October 9th, 2024 (7 AM - 7 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://www.eventbrite.com/e/digital-health-ai-innovation-summit-tickets-915894935407?aff=ebdssbdestsearch&keep_tld=1"
  },
  {
    id: 33,
    title: "16th Annual Bioprocessing Summit",
    start: new Date(2024,7,19),
    end: new Date(2024, 7, 23),
    datetime: "August 19th - August 22nd, 2024",
    location: "Boston, MA",
    description: "",
    url: "https://www.bioprocessingsummit.com/"
  },
  {
    id: 34,
    title: "Portal Innovations End of Summer Happy Hour",
    start: new Date(2024, 7, 28, 17),
    end: new Date(2024, 7, 28, 20),
    datetime: "August 28th, 2024 (5 - 8 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://www.portalinnovations.com/events/boston-end-of-summer-happy-hour/"
  },
  {
    id: 35,
    title: "Science on Tap - Sartorius",
    start: new Date(2024, 8, 10, 17),
    end: new Date(2024, 8, 10, 20),
    datetime: "September 10th, 2024 (5 - 8 PM)",
    location: "Woburn, MA",
    description: "",
    url: "https://www.massbio.org/member-events/science-on-tap-sartorius/"
  },
  {
    id: 36,
    title: "Newton Labshares End of Summer Mixer",
    start: new Date(2024, 8, 12, 15, 30),
    end: new Date(2024, 8, 12, 18),
    datetime: "September 12th, 2024 (3:30 - 6 PM)",
    location: "Newton, MA",
    description: "",
    url: "https://docs.google.com/forms/d/e/1FAIpQLSfUpPXS7X8_oMZy7tp5yRjNhH-LeE3EgHZXg-jXn_DEeQuGFg/viewform"
  },
  {
    id: 37,
    title: "Portal Playground @ Bayer Co.Lab Roof Deck",
    start: new Date(2024, 8, 16, 17),
    end: new Date(2024, 8, 16, 19),
    datetime: "September 16th, 2024 (5 - 7 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://lu.ma/gs7ptchr"
  },
  {
    id: 38,
    title: "Gene-ius Break: Cambridge, MA",
    start: new Date(2024, 8, 17, 16, 30),
    end: new Date(2024, 8, 17, 20),
    datetime: "September 17th, 2024 (4:30 - 8 PM)",
    location: "Cambridge, MA",
    description: "",
    url: "https://info.biocytogen.com/geneius-break-cambridge-09/24"
  },
  {
    id: 39,
    title: "",
    start: new Date(2024, 8, 18, 16),
    end: new Date(2024, 8, 18, 19),
    datetime: "September 18th, 2024 (4 - 7 PM)",
    location: "Natick, MA",
    description: "",
    url: "https://www.mwlifesciencesnetwork.org/fall-mixer-2024?utm_campaign=71993d9f-e020-4982-b318-0a46ea466464&utm_source=so&utm_medium=mail&utm_content=39dab9a1-0176-42f6-ae4f-4b307e1c3ce8&configurationId=6def76c0-f3c2-403d-9faa-65122ee14c53&actionId=6d3c89d0-9be3-11cf-9a8c-0f096456f216&cid=59fd543a-5047-4cb8-999b-732c99a26583"
  },
  {
    id: 40,
    title: "Portal Pours - BioSynergy: Boston Non-Profits Shaping the Future",
    start: new Date(2024, 8, 23, 16),
    end: new Date(2024, 8, 23, 20),
    datetime: "September 23rd, 2024 (4 - 8 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://23230704.hs-sites.com/2024-sept-boston-portal-pours"
  },
  {
    id: 41,
    title: "Biotech Tuesday",
    start: new Date(2024, 8, 24, 18),
    end: new Date(2024, 8, 24, 21),
    datetime: "September 24th, 2024 (6 - 9 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://lp.constantcontactpages.com/ev/reg/pyca7sg/lp/7429ae7c-d8c7-49b2-bd87-f34234c68df9?source_id=36b14848-9d12-4947-b10a-9c89b9190160&source_type=em&c=wtx5iNdbpSQOfZSPlJo8tz3Im9KPlpwJ4iERiUK88kEls3vMX1yJBQ=="
  },
  {
    id: 42,
    title: "MassBio North Shore Regional Mixer",
    start: new Date(2024, 8, 19, 17),
    end: new Date(2024, 8, 19, 19),
    datetime: "September 19th, 2024 (5 - 7 PM)",
    location: "Salem, MA",
    description: "",
    url: "https://www.massbio.org/events/listing/2024-north-shore-regional-mixer/"
  },
  {
    id: 43,
    title: "BVS' Biotech Community Oktoberfest ",
    start: new Date(2024, 8, 19, 14),
    end: new Date(2024, 8, 19, 18),
    datetime: "September 19th, 2024 (2 - 6 PM)",
    location: "Watertown, MA",
    description: "",
    url: "https://www.eventbrite.com/e/bvs-biotech-community-oktoberfest-event-in-watertown-tickets-989032441657?aff=oddtdtcreator&utm_campaign=website&utm_medium=email&utm_source=sendgrid.com"
  },
  {
    id: 44,
    title: "MassBio Oktoberfest Mixer",
    start: new Date(2024, 9, 24, 17),
    end: new Date(2024, 9, 24, 19),
    datetime: "October 24th, 2024 (5 - 7 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://www.massbio.org/events/listing/oktoberfest-mixer/"
  },
  {
    id: 45,
    title: "M2D2: Planning & Funding Your Next Lab Space",
    start: new Date(2024, 8, 18, 11),
    end: new Date(2024, 8, 18, 14),
    datetime: "September 18th, 2024 (11 AM - 2 PM)",
    location: "Lowell, MA",
    description: "",
    url: "https://www.eventbrite.com/e/growing-pains-sorted-planning-funding-your-next-lab-space-tickets-898599724967?aff=social"
  },
  {
    id: 46,
    title: "Plasmidsaurus Meetup: Boston",
    start: new Date(2024, 8, 17, 18),
    end: new Date(2024, 8, 17, 20),
    datetime: "September 17th, 2024 (6 - 8 PM)",
    location: "Boston",
    description: "",
    url: "https://lu.ma/n0dx7hir"
  },
  {
    id: 47,
    title: "MIT Decentralized AI Roundtable",
    start: new Date(2024, 8, 12, 12),
    end: new Date(2024, 8, 12, 14),
    datetime: "September 12, 2024 (12 - 2 PM)",
    location: "Cambridge, MA",
    description: "",
    url: "https://lu.ma/mn826b81"
  },
  {
    id: 48,
    title: "Annual Female Founder and Funder Breakfast",
    start: new Date(2024, 8, 11, 9),
    end: new Date(2024, 8, 11, 11),
    datetime: "September 11th, 2024 (9 - 11 AM)",
    location: "Boston, MA",
    description: "",
    url: "https://lu.ma/1vpy24uf"
  },
  {
    id: 49,
    title: "Boston Foodtech Social",
    start: new Date(2024, 8, 19, 15),
    end: new Date(2024, 8, 19, 17),
    datetime: "September 19th, 2024 (3 - 5 PM)",
    location: "Cambridge, MA",
    description: "",
    url: "https://lu.ma/yug94alz"
  },
  {
    id: 50,
    title: "Biomaterials + stem cells discussion w/ age",
    start: new Date(2024, 8, 19, 18),
    end: new Date(2024, 8, 19, 21),
    datetime: "September 19th, 2024 (6 - 9 PM)",
    location: "Cambridge, MA",
    description: "",
    url: "https://lu.ma/uod7n05q?tk=algpgo"
  },
  {
    id: 51,
    title: "BioMix Launch Party",
    start: new Date(2024, 8, 24, 18),
    end: new Date(2024, 8, 24, 21),
    datetime: "September 24th, 2024 (6 - 9 PM)",
    location: "Boston, MA",
    description: "",
    url: "https://lu.ma/4jegabm9?tk=NXPBcf"
  },

]

export default events