
import 'bootstrap/dist/css/bootstrap.css';
import {Card, CardBody, CardTitle} from 'react-bootstrap';
import Row from 'react-bootstrap/Row'
import ListGroup from 'react-bootstrap/ListGroup';

function SponsorshipCard() {
    return (
        <Card className = "dash-card">
            <Card.Header as="h5">Events Looking for Sponsors</Card.Header>
            <Card.Text>
                Discover exciting events to sponsor and connect with event 
                organizers to amplify your brand's impact
            </Card.Text>
            <ListGroup className="list-group-flush">
                <ListGroup.Item>There currently are no events looking for sponsorship</ListGroup.Item>
            </ListGroup>
        </Card>
    );
}


export default SponsorshipCard;