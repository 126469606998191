import Form from 'react-bootstrap/Form';

function RegionSelect() {
  return (
    <Form.Select aria-label="Default select example">
      <option value="1">Boston</option>
    </Form.Select>
  );
}

export default RegionSelect;