
import 'bootstrap/dist/css/bootstrap.css';
import {Card, CardBody, CardTitle} from 'react-bootstrap';
import Row from 'react-bootstrap/Row'

function FeaturedEvents() {
    return (
        <Card className = "dash-card">
            <CardBody>
                <CardTitle>Featured Events</CardTitle>
                <Row className = "whole-col">
                    <hr/>
                </Row>
                <Row className="whole-col">
                    <b>There currently are no featured events scheduled</b>
                </Row>
                <Row className="whole-col">
                    <i>Want to get your event featured?</i>
                </Row>
                <Row className="Whole-col">
                    <a href = "https://forms.gle/4M1uF7BQWFE6aXRJ8" target="_blank" rel="noreferrer">
                        <button style = {{borderColor:"black", backgroundColor:"white", color:"black"}}>Click Here!</button>
                    </a>
                </Row>
            </CardBody>
        </Card>
    );
}

export default FeaturedEvents;
